import GameReborn from "../GameReborn";
import React, { useEffect, useRef } from "react";
import Peer from "peerjs";

const { useState } = require("react")

export default function PeerMultiplayerHostGame(props) {
    const peerInstance = useRef(null)
    const playersRef = useRef([])
    const [playerCount, setPlayerCount] = useState(0)
    const defaultGameData = useRef(false)

    const gameId = useRef("error")

    if (gameId.current == "error") {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        if (userData != undefined) {
            const uid = userData.uid
            gameId.current = uid
        }
    }

    useEffect(() => {
        if (peerInstance.current == null) {
            iniPeer()
        }
        const unload = async (e) => {
            closeCall()
        }
        window.addEventListener('beforeunload', unload)
        return () => {
            window.removeEventListener(
                'beforeunload',
                unload,
            );
        };
    }, [])

    const closeCall = () => {
        peerInstance.current.destroy()
        playersRef.current = []
    }


    const iniPeer = () => {
        const peer = new Peer("against-the-horde-ID-" + gameId.current, {
            config: {'iceServers': [
                {
                  urls: "turn:global.relay.metered.ca:80",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
                {
                  urls: "turn:global.relay.metered.ca:80?transport=tcp",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
                {
                  urls: "turn:global.relay.metered.ca:443",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
                {
                  urls: "turns:global.relay.metered.ca:443?transport=tcp",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
            ]}
          });

        // Listening
        peer.on('open', (id) => {
            playersRef.current.push({connection: null, id: id})
            setPlayerCount(playersRef.current.length)
            peer.on("connection", (conn) => {
                // Handle connection
                conn.on("open", () => {
                    playersRef.current.push({connection: conn, id: conn.peer})
                    setPlayerCount(playersRef.current.length)
                    sendPlayersIdArray()
                    sendDefaultGameDataTo(conn)
                })
                conn.on("close", () => {
                    playersRef.current.splice(playersRef.current.findIndex(a => a.id == conn.id) , 1)
                    setPlayerCount(playersRef.current.length)
                    sendPlayersIdArray()
                });

                // Handle game data
                conn.on("data", (data) => {
                    handleGameData(data)
                });
            });
        });

        peerInstance.current = peer;
    }

    const sendPlayersIdArray = () => {
        let a = []
        playersRef.current.forEach((ref) => {
            if (ref.id) {
                a.push("" + ref.id)
            }
        })
        sendDataToOtherPlayers({ playersIdArray: a })
    }

    const sendDataToOtherPlayers = (data) => {
        playersRef.current.forEach((ref) => {
            if (ref.connection) {
                ref.connection.send(data)
            }
        })
    }

    const handleGameData = (data) => {
        
    }

    const sendGameData = (data) => {
        sendDataToOtherPlayers({ time: "", gameData: data })
    }

    const sendDefaultGameDataTo = (conn) => {
        conn.send({ time: "", gameData: defaultGameData.current })
    }




    async function iniGroup_setup(options, selectedDeck, tokens, deckSizeAtStart) {
        const value = {
            options: options,
            selectedDeck: selectedDeck,
            tokens: tokens,
            deckSizeAtStart: deckSizeAtStart
        }
        sendGameData({ group_setup: value })
    }

    async function updateBoard(newBoard) {
        sendGameData({ board: newBoard  })
    }

    async function updateDeck(newDeck) {
        sendGameData({ deck: newDeck })
    }

    async function updateLifepoints(newLife) {
        sendGameData({ lifepoints: newLife })
    }

    async function updateGroup_graveyard(newValue) {
        sendGameData({ group_graveyard: newValue })
    }

    async function updateGroup_newTurn(newValue) {
        sendGameData({ group_newTurn: newValue })
    }

    async function updateGroup_other(newValue) {
        sendGameData({ group_other: newValue })
    }

    const setIsPlaying = (value) => {
        peerInstance.current.destroy()
        props.setIsPlaying(value)
    }
    
    return (
        <GameReborn selectedDeck={props.selectedDeck}
            setIsPlaying={setIsPlaying}
            options={props.options}
            cloudFunctions={{
                iniGroup_setup: iniGroup_setup,
                updateBoard: updateBoard,
                updateDeck: updateDeck,
                updateGroup_graveyard: updateGroup_graveyard,
                updateGroup_newTurn: updateGroup_newTurn,
                updateGroup_other: updateGroup_other,
                updateLifepoints: updateLifepoints,
                defaultGameData: defaultGameData
            }}
            playerCount={playerCount - 1}
        >
        </GameReborn>
    )
}